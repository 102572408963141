'use strict'

//swiper
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Controller, EffectFade } from 'swiper/modules';

Swiper.use([Navigation, Pagination, Autoplay, Controller, EffectFade]);

window.slider = window.swiper = function (sliderSelector, sliderSettings = {}) {
    if (document.querySelector(sliderSelector) !== null) {
        let slider = new Swiper(sliderSelector, sliderSettings);
        return slider;
    }
};
