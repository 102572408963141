document.addEventListener("DOMContentLoaded", () => {
    const sliderName = 'page-slider';
    const sliderSetings = {
        init: true,
        loop: true,
        autoplay:  {
            pause: 10000
        },
        centeredSlides: false,
        effect: 'fade',
        freeMode: false,
        loopedSlides: 0,
        navigation: {
            nextEl: '.js-' + sliderName + '-next',
            prevEl: '.js-' + sliderName + '-prev',
            disabledClass: sliderName + '__action_disabled',
            lockClass: sliderName + '__action_lock'
        },
        pagination: {
            bulletActiveClass: sliderName + '__pagination-item_active',
            bulletClass: sliderName + '__pagination-item',
            bulletElement: 'div',
            clickable: true,
            clickableClass: sliderName + '__pagination_clickable',
            currentClass: sliderName + '__pagination-item_current',
            el: '.' + sliderName + '__pagination'
        },
        preloadImages: true,
        roundLengths: true,
        speed: 10,
        slidesPerView: 1,
        spaceBetween: 0,
        thumbs: false,
        containerModifierClass: sliderName + '_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        breakpoints: {
            [window.adaptive.SM]: {
                slidesPerView: 1,
            },
            [window.adaptive.MD]: {
                slidesPerView: 1,
            },
            [window.adaptive.LG]: {
                slidesPerView: 1,
            },
            [window.adaptive.XL]: {
                slidesPerView: 1,
            },
            [window.adaptive.XXL]: {
                slidesPerView: 1,
            },
        }
    };

    window.slider('.js-' + sliderName, sliderSetings);
});