import _ from 'lodash';
import { adaptive } from "./_adaptive";
import { toggle } from "slide-element";

/* adaptive */
window.adaptive = adaptive;

/* LODASH */
window.lodash = window._ = _;

/* TABS */
window.tabs = function (selector) {
    const tabsList = document.querySelectorAll(selector);

    let currentTab = _.head(tabsList);

    _.each(tabsList, (item) => {

        if (item.classList.contains('current')) {
            currentTab = item;
        }

        item.addEventListener('click', (event) => {
            event.preventDefault();

            _.each(tabsList, (sibling) => {
                selectTabContent(sibling);
            });

            item.classList.add('current');
            selectTabContent(item, true);
        });
    });

    selectTabContent(currentTab, true);
};
function selectTabContent(item, show = false) {
    const tabContent = document.querySelector(item.getAttribute("href"));
    const tabHead = document.querySelector('[href="' + item.getAttribute("href") + '"]');

    if (!item.classList.contains('current')) {
        item.classList.add('current');
    }

    if (tabContent !== null) {
        if (show) {
            tabContent.classList.add('current');
        } else {
            tabHead.classList.remove('current');
            tabContent.classList.remove('current');
        }
    }
}

/* HEADER */
document.addEventListener("DOMContentLoaded", () => {
    /* header on scroll */
    // let scrollBefore = 0;
    // const $header = document.querySelector('[data-header-wrapper]');

    // document.addEventListener("scroll", (event) => {
    //     const scrolled = window.scrollY;

    //     if (scrollBefore > scrolled) {
    //         $header.classList.remove('header-wrapper_scrolled');
    //         scrollBefore = scrolled;
    //     } else {
    //         scrollBefore = scrolled;
    //         if (scrolled + 5 > $header.offsetHeight) {
    //             $header.classList.add('header-wrapper_scrolled')
    //         }
    //     }
    // });

    /* mburger component */
    // const $mobileMenuTriger = document.querySelector('[data-show-mmenu]');
    // const $mobileMenu = document.querySelector('[data-mmenu]');
    // const $burger = document.querySelector("mm-burger");
    // let mobileMenuStatus = false;

    // $mobileMenuTriger.addEventListener("click", () => {
    //     mobileMenuStatus = !mobileMenuStatus;

    //     if (mobileMenuStatus) {
    //         $burger.state = "cross";
    //         $mobileMenu.style.height = "calc(100vh - " + $header.offsetHeight + "px)";
    //         $header.classList.add('header_showed-menu');
    //     } else {
    //         $burger.state = "bars";
    //         $mobileMenu.style.height = "0";
    //         $header.classList.remove('header_showed-menu');
    //     }
    // });
    const $mobileMenuTriger = document.querySelector(".js-mobile-menu");
    const $mobileDropdown = document.querySelector("[data-mobile-dropdown]");
    $mobileMenuTriger.addEventListener("click", () => {
        $mobileMenuTriger.classList.toggle("_openned");
        toggle($mobileDropdown);
    })
});

/* SCROLL LINKS */
window.animateScrollTo = function (selector = '[data-scroll-link]', options = {}) {
    let scrollToOptions = {
        speed: 800
    };

    const scrollLinks = document.querySelectorAll(selector);

    _.each(scrollLinks, (item) => {
        item.addEventListener('click', (event) => {
            event.preventDefault();

            const scrollTarget = document.querySelector(item.getAttribute("href"));

            if (scrollTarget !== null) {
                animateScrollTo(scrollTarget, _.merge(scrollToOptions, options));
            }
        });
    });
};
document.addEventListener("DOMContentLoaded", () => {
    window.animateScrollTo();
});
