'use strict';

import SimpleBar from 'simplebar';

/* SELECT */
document.addEventListener("DOMContentLoaded", () => {
    let x, i, j, l;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("custom-select");
    l = x.length;
    for(i = 0; i <l; i++) {
        let baseElement = x[i];
        let ll, selElmnt, a, b, c;
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        let bSub = document.createElement("DIV");
        bSub.setAttribute("class", "select-items-enternal");
        let bSelectScroll = document.createElement("DIV");
        bSelectScroll.classList.add("select-scroll");
        bSelectScroll.appendChild(bSub);        
        b.appendChild(bSelectScroll);
        for (j = 0; j < ll; j++) {
            c = document.createElement("DIV");
            c.classList.add("select-item");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, sl, yl;
                s = selElmnt;
                sl = s.length;
                h = b.previousSibling;
                for (i = 0; i < sl; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        s.dispatchEvent(new Event('change'));
                        h.innerHTML = this.innerHTML;
                        // y = this.parentNode.getElementsByClassName("same-as-selected");
                        y = baseElement.getElementsByClassName("same-as-selected");
                        yl = y.length;
                        for (k = 0; k < yl; k++) {
                            y[k].classList.remove("same-as-selected");
                        }
                        this.classList.add("same-as-selected");
                        break;
                    }
                }
                h.click();
            });
            bSub.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
        });

        selElmnt.addEventListener('change', function (e) {
            a.innerHTML = this.options[this.selectedIndex].text
            let childrenList = bSub.children;
            
            for (let j = 0; j < childrenList.length; j++) {
                childrenList[j].classList.remove("same-as-selected");

                if (window.getComputedStyle(this.options[j]).display === 'none') {
                    childrenList[j].style.display = "none";
                } else {
                    childrenList[j].style.display = "";
                }
            }

            childrenList[this.selectedIndex].classList.add('same-as-selected');
        });

        new SimpleBar(bSelectScroll);
    }

    function closeAllSelect(elmnt) {
        /* A function that will close all select boxes in the document,
        except the current select box: */
        let x, y, i, xl, yl, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        xl = x.length;
        yl = y.length;
        for (i = 0; i < yl; i++) {
            if (elmnt == y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < xl; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }

    document.addEventListener("click", closeAllSelect);
});