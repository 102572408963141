document.addEventListener("DOMContentLoaded", () => {
    const sliderName = 'brands-slider';
    const sliderSetings = {
        init: true,
        loop: true,
        autoplay:  {
            pause: 10000
        },
        centeredSlides: false,
        effect: 'slide',
        freeMode: false,
        // loopedSlides: 10,
        navigation: false,
        pagination: false,
        preloadImages: true,
        roundLengths: true,
        speed: 800,
        slidesPerView: 'auto',
        spaceBetween: 27,
        thumbs: false,
        containerModifierClass: sliderName + '_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        // breakpoints: {
        //     [window.adaptive.SM]: {
        //         slidesPerView: 'auto',
        //         spaceBetween: 27,
        //     },
        //     [window.adaptive.MD]: {
        //         slidesPerView: 'auto',
        //         spaceBetween: 27,
        //     },
        //     [window.adaptive.LG]: {
        //         slidesPerView: 'auto',
        //         spaceBetween: 27,
        //     },
        //     [window.adaptive.XL]: {
        //         slidesPerView: 'auto',
        //         spaceBetween: 27,
        //     },
        //     [window.adaptive.XXL]: {
        //         slidesPerView: 'auto',
        //         spaceBetween: 27,
        //     },
        // }
    };

    window.slider('.js-' + sliderName, sliderSetings);
});